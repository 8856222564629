
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import { MessageBox } from "element-ui";
import Pagination from "@/comp/Pagination/index.vue";
import { saveCategory, deleteCategory, categoryList } from "@/api/request/system";

//组件
@Component({
  name: "Category",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private listLoading: boolean = true;

  //请求参数
  private listQuery: any = {
    //页码数据
    page: 1,
    page_size: 20,

    //上传数据
    name: "", //分类名称
    belong: 0, //归属模块
  };

  //归属列表
  private belongList: any[] = [
    { belong: 0, name: "全部归属模块" },
    { belong: 1, name: "礼物" },
    { belong: 2, name: "广场互动" },
    { belong: 3, name: "钻石商城" },
    { belong: 4, name: "互动币商城" },
  ];

  //创建
  created() {
    //获取数据
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await categoryList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理创建
  private handleCreate(): void {
    //数据赋值
    this.addForm = {
      //临时数据
      sort_str: "",

      //请求数据
      id: 0, //ID
      sort: 0, //排序
      name: "", //分类名称
      belong: 0, //归属模块：1-礼物，2-广场互动，3-道具商城，4-互动币商城
      status: 0, //状态 0-正常 1-禁用 2-删除
    };
    this.dialogStatus = "create";

    //显示界面
    this.dialogVisible = true;
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }

  //处理编辑
  private handleUpdate(row: any): void {
    //数据赋值
    this.addForm = {
      //临时数据
      sort_str: String(row.sort),

      //请求数据
      id: row.id, //ID
      sort: row.sort, //排序
      name: row.name, //分类名称
      belong: row.belong, //归属模块：1-礼物，2-广场互动，3-道具商城，4-互动币商城
      status: row.status, //状态 0-正常 1-禁用 2-删除
    };
    this.dialogStatus = "update";

    //显示界面
    this.dialogVisible = true;
  }

  //处理删除
  private handleDelete(row: any): void {
    //显示提示
    MessageBox.confirm("删除操作不可恢复，确定删除吗？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        //处理删除操作
        await deleteCategory({ id: row.id });

        //显示提示
        this.$notify({
          title: "成功",
          message: "删除成功",
          type: "success",
          duration: 2000,
        });

        //获取列表
        this.getList();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取归属模块名称
  private getBelongName(belong: number): string {
    //数据赋值
    for (var i: number = 0; i < this.belongList.length; i++) {
      if (belong == this.belongList[i].belong) {
        return this.belongList[i].name;
      }
    }
    return "";
  }

  //--------------------------------- 添加/编辑 ---------------------------------
  //定义变量
  private dialogStatus: string = "";
  private dialogVisible: boolean = false;
  private dialogLoading: boolean = false;
  private titleMap = { update: "编辑系统分类", create: "新增系统分类" };

  //添加表单
  private addForm: any = {
    //临时数据
    sort_str: "",

    //请求数据
    id: 0, //ID
    sort: 0, //排序
    name: "", //分类名称
    belong: 0, //归属模块：1-礼物，2-广场互动，3-道具商城，4-互动币商城
    status: 0, //状态 0-正常 1-禁用 2-删除
  };

  //状态选择
  private statusSelect: any[] = [
    { status: 0, name: "正常" },
    { status: 1, name: "禁用" },
    { status: 2, name: "删除" },
  ];

  //归属列表
  private belongSelect: any[] = [
    { belong: 0, name: "请选择归属模块" },
    { belong: 1, name: "礼物" },
    { belong: 2, name: "广场互动" },
    { belong: 3, name: "钻石商城" },
    { belong: 4, name: "互动币商城" },
  ];

  //创建消息
  private async createData() {
    //数据处理
    if (this.addForm.name == "") return this.$message.error("请输入分类名称");
    if (this.addForm.belong == 0) return this.$message.error("请选择归属模块");
    if (Number(this.addForm.sort_str) == 0) return this.$message.error("排序 > 0");

    //数据赋值
    this.addForm.sort = Number(this.addForm.sort_str);

    //保存任务
    await saveCategory(this.addForm);

    //隐藏等待
    this.dialogLoading = false;

    //隐藏界面
    this.dialogVisible = false;

    //显示提示
    this.$notify({
      title: "成功",
      message: "创建成功",
      type: "success",
      duration: 2000,
    });

    //获取列表
    this.getList();
  }

  //更新消息
  private async updateData() {
    //数据处理
    if (this.addForm.name == "") return this.$message.error("请输入分类名称");
    if (this.addForm.belong == 0) return this.$message.error("请选择归属模块");
    if (Number(this.addForm.sort_str) == 0) return this.$message.error("排序 > 0");
    //数据赋值
    this.addForm.sort = Number(this.addForm.sort_str);

    //保存任务
    await saveCategory(this.addForm);

    //隐藏等待
    this.dialogLoading = false;

    //隐藏界面
    this.dialogVisible = false;

    //显示提示
    this.$notify({
      title: "成功",
      message: "编辑成功",
      type: "success",
      duration: 2000,
    });

    //获取列表
    this.getList();
  }

  //是否创建
  private isCreate(): boolean {
    return this.dialogStatus === "create";
  }
}
